body {
  background: #3d3e3f;
}

@media only screen and (max-width: 767px) {
  .ui.grid > .stackable.stackable.row > .column,
  .ui.stackable.grid > .column.grid > .column,
  .ui.stackable.grid > .column.row > .column,
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .row > .column,
  .ui.stackable.grid > .row > .wide.column,
  .ui.stackable.grid > .wide.column {
    padding: 7px 0 !important;
  }
}

ul {
  padding: 0;
}

#drift-widget-container {
  z-index: 100 !important;
}

.ui.modal {
  will-change: unset;
  position: relative;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
  pointer-events: none;
}
